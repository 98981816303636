.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.pagination-number {
  padding-right: 10rem;
  display: inline-block;
}
.pagination-btn:hover {
  opacity: 0.3;
  cursor: pointer;
}
