.arrow-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}
.arrow {
  color: black;
  background-color: #e6e6e6 !important;
  opacity: 0.5;
}
.arrow:hover {
  opacity: 0.3;
  color: grey;
  background-color: #e6e6e6 !important;
  cursor: pointer;
}
