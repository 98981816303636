.category {
  padding: 0 100px;
  border-bottom: 1px solid black;
}
.categoryBar {
  background-color: "white";
  padding-left: "9.6vw";
  display: flex;
  gap: 100px;
  border-bottom: " 1px solid black";
}
